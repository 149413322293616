<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <td></td>
                  <td class="text-right">Cantidad</td>
                  <td class="text-right">Unitario</td>
                  <td class="text-right">Importe</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Electores</th>
                  <td class="text-right">{{ budget.voters }}</td>
                  <td class="text-right">{{ budget.voter_price }}</td>
                  <td class="text-right">{{ budget.voters_price }}</td>
                </tr>
                <tr>
                  <th>Confirmaciones</th>
                  <td class="text-right">{{ budget.confirmations }}</td>
                  <td class="text-right">{{ budget.notification_price }}</td>
                  <td class="text-right">{{ budget.confirmations_price }}</td>
                </tr>
                <tr>
                  <th>Notificaciones adicionales</th>
                  <td class="text-right">{{ budget.notifications }}</td>
                  <td class="text-right">{{ budget.notification_price }}</td>
                  <td class="text-right">{{ budget.notifications_price }}</td>
                </tr>
                <tr>
                  <th colspan="3" class="text-right">TOTAL A PAGAR</th>
                  <th class="text-right">{{ budget.total }}</th>
                </tr>
                <tr>
                  <th colspan="3" class="text-right">PAGADO</th>
                  <th class="text-right">{{ budget.payment_amount }}</th>
                </tr>
                <tr v-if="budget.balance_cents > 0">
                  <th colspan="3" class="text-right">SALDO</th>
                  <th class="text-right">{{ budget.balance }}</th>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col cols="12" md="6">
          <div v-if="showButtons">
            <p class="display-1">Haz click en la imagen y paga con:</p>
            <v-avatar
              @click.prevent="yape"
              size="100"
              rounded
              class="elevation-5 mx-5"
            >
              <v-img src="@/assets/yape.png"></v-img>
            </v-avatar>
            <v-avatar
              @click.prevent="tunki"
              size="100"
              rounded
              class="elevation-5 mx-5"
            >
              <v-img src="@/assets/tunki.jpg"></v-img>
            </v-avatar>
          </div>
          <div v-if="buttonSelected">
            <v-row>
              <v-col>
                <v-img
                  :src="require('@/assets/' + buttonSelected + '_qr.jpg')"
                  max-height="264"
                  max-width="264"
                  class="text-right"
                ></v-img>
              </v-col>
              <v-col>
                <p>Ingresa este monto {{ amount }}</p>
                <p>
                  Ingresa este código como comentario en tu {{ buttonSelected }}
                </p>
                <p>{{ token }}</p>
                <v-btn @click.prevent="confirmPayment">
                  YA TRANSFERÍ
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div v-if="showThanks">
            <p>{{ thanksMessage }}</p>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="red"
        class="mx-auto mt-5"
        width="40%"
        rounded
        dark
        :to="getRoute('loaddata')"
      >
        <v-icon>mdi-arrow-left-bold</v-icon>Atrás
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="red"
        class="mx-auto mt-5"
        width="40%"
        rounded
        dark
        :to="getRoute('results')"
      >
        Siguiente<v-icon>mdi-arrow-right-bold</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import electionCanEdit from "@/mixins/electionCanEdit";
import elections from "@/services/elections";

export default {
  name: "BallotPayment",
  mixins: [electionCanEdit],
  props: ["election"],
  data: () => ({
    budget: {},
    showButtons: true,
    showThanks: false,
    showTransfer: false,
    // showTunki: false,
    buttonSelected: null,
    token: null,
    amount: null,
    thanksMessage: null
  }),
  components: {},
  watch: {
    election() {
      this.loadBudget();
    }
  },
  computed: {},
  methods: {
    loadBudget() {
      elections.budget(this.election).then(response => {
        this.budget = response.data;
      });
    },
    yape() {
      elections.yape(this.election).then(response => {
        this.token = response.data.token;
        this.amount = response.data.total;
        this.showButtons = false;
        this.buttonSelected = "Yape";
      });
    },
    tunki() {
      elections.tunki(this.election).then(response => {
        this.token = response.data.token;
        this.amount = response.data.total;
        this.showButtons = false;
        this.buttonSelected = "Tunki";
      });
    },
    confirmPayment() {
      elections.confirmPayment(this.election, this.token).then(response => {
        this.showButtons = false;
        this.buttonSelected = null;
        this.showThanks = true;
        this.thanksMessage = response.data.message;
      });
    },
    getRoute(option) {
      return "/wizard/" + this.election.id + "/" + option;
    }
  }
};
</script>