<template>
  <div class="notifications-election">
    <wizard />
    <h2>{{ election.name }}</h2>
    <componentPayment :election="election" />
  </div>
</template>

<script>
import loadElection from "@/mixins/loadElection";

import wizard from "@/components/wizard/wizard.vue";
import componentPayment from "@/components/ballots/BallotPayment.vue";

export default {
  mixins: [loadElection],
  components: {
    wizard,
    componentPayment
  }
};
</script>